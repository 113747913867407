import React from "react";
import Slider from "react-slick";

import "../../styles/testimonial.css";

import ava01 from "../../assets/all-images/ava-1.jpg";
import ava02 from "../../assets/all-images/ava-2.jpg";
import ava03 from "../../assets/all-images/ava-3.jpg";
import ava04 from "../../assets/all-images/ava-4.jpg";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows:false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
<Slider {...settings}>
  <div className="testimonial py-4 px-3">
    <p className="section__description">
      Renting a car from Rental Pro was a breeze! The process was quick,
      and the vehicle was in top-notch condition. Highly recommend their services.
    </p>

    <div className="mt-3 d-flex align-items-center gap-4">
      <img src={ava01} alt="" className="w-25 h-25 rounded-2" />

      <div>
        <h6 className="mb-0 mt-3">Emma Johnson</h6>
        <p className="section__description">Satisfied Customer</p>
      </div>
    </div>
  </div>

  <div className="testimonial py-4 px-3">
    <p className="section__description">
      Exploring new destinations with Rental Pro made my trip unforgettable.
      Quality cars, excellent service, and affordable prices. Will use again!
    </p>

    <div className="mt-3 d-flex align-items-center gap-4">
      <img src={ava02} alt="" className="w-25 h-25 rounded-2" />

      <div>
        <h6 className="mb-0 mt-3">Michael Davis</h6>
        <p className="section__description">Happy Traveler</p>
      </div>
    </div>
  </div>

  <div className="testimonial py-4 px-3">
    <p className="section__description">
      Renting a car has never been this convenient! Thanks to Rental Pro,
      I had a smooth and enjoyable travel experience. Great selection and service.
    </p>

    <div className="mt-3 d-flex align-items-center gap-4">
      <img src={ava03} alt="" className="w-25 h-25 rounded-2" />

      <div>
        <h6 className="mb-0 mt-3">Sophia Miller</h6>
        <p className="section__description">Repeat Customer</p>
      </div>
    </div>
  </div>

  <div className="testimonial py-4 px-3">
    <p className="section__description">
      Exceptional service from Rental Pro! The vehicle exceeded my expectations,
      and the rental process was seamless. Will definitely choose them again.
    </p>

    <div className="mt-3 d-flex align-items-center gap-4">
      <img src={ava04} alt="" className="w-25 h-25 rounded-2" />

      <div>
        <h6 className="mb-0 mt-3">David Thompson</h6>
        <p className="section__description">Happy Customer</p>
      </div>
    </div>
  </div>
</Slider>
  );
};

export default Testimonial;
